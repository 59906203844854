var $                    = require('jquery'),
    fastclick            = require('fastclick'),
    flickity             = require('flickity'),
    flickityImgsLoaded   = require('flickity-imagesloaded'),
    Lightbox             = require('./lightbox.js'),
    imgsLoaded           = require('imagesLoaded'),
    cookies              = require('./cookies.js');

// window fade in
$(document).ready(function(){
  $('.contentwrapper').addClass('show');
});


if (document.getElementById('hero')) {
  $('.subnav').addClass('hero__active');
}


// flickity
if (document.querySelector('.hero__slider')) {
  var heroslider = document.querySelector('.hero__slider');
  var flkty = new flickity( heroslider, {
      cellAlign: 'left',
      wrapAround: true,
      contain: true,
      autoPlay: 5000,
      setGallerySize: true,
      imagesLoaded: true,
      lazyLoad: 3,
      selectedAttraction: 0.2,
      friction: 0.9,
      prevNextButtons: false,
      pageDots: true
  });
}


// viewport size
function viewportsize(){
  hh = $(window).height();
  hw = $(window).width();
}
viewportsize();
$(window).resize(viewportsize);


// scroll offset
$(window).scroll(function() {
    var scroll = $(this).scrollTop();

    offset = hh*0.3;

    if (scroll >= offset) {
        $('.nav').addClass('hidenav');
        $('.subnav').addClass('asnav');
        $('.slide__teaser').addClass('hide');
    } else {
        $('.nav').removeClass('hidenav');
        $('.subnav').removeClass('asnav');
        $('.slide__teaser').removeClass('hide');
    }
});


// Use data-[width|height] attrs to set aspect ratio
function applyAspectRatio() {
  $('img').each(function (i, el) {
    var $el = $(el);
    
    if ($el.data('width')) {
      var $wrapper = $el.closest('.image-wrapper');
      var ratio = $el.data('height') / $el.data('width') * 100;
      
      if (!$wrapper.hasClass('image-16-5-wrapper')) {
        $wrapper.css('padding-bottom', ratio + '%');     
      }   
    }
  });
}


// Add complete class to all loaded images 
function setupImages() {
  
  applyAspectRatio();
    
  imgsLoaded(document.querySelector('body'), function () {        
    setTimeout(function () {
      $('img').addClass('image-complete');
      $('img').closest('.image-wrapper').addClass('complete');      
    });
  });
}


// Setup Flickity as horizontal scroller
// With no page dots or buttons  
// @param {string} DOM Selector 
// @param {string} DOM Cell selector 
function setupFlickity(selector, cellSelector) {

  if (document.querySelector(selector)) {
    new Flickity(selector, {      
      // Start at second one 
      initialIndex: 0,
      // Specify selector class
      cellAlign: 'left',
      cellSelector: cellSelector,
      // Do not snap cells to end position 
      freeScroll: true,
      // Hide buttons and dots 
      prevNextButtons: false, 
      pageDots: false,
      imagesLoaded: true
    });
  }
}

function setupListeners() {

  // back to top
  $('.backtotop').click(function() {
      $('html,body').animate({
          scrollTop: 0
      }, 600);
  });


  // hamburger
  var hamburger = $('.mobile__nav__burger');
      hamburger.click(function() {
      hamburger.toggleClass('mobile__nav__burger__active');
      $('.mobile__nav').toggleClass('mobile__nav__active');
      return false;
  });


  // footer under the fold
  $(window).resize(function() {
      var footerHeight = $('footer').outerHeight();
      $('.content').css('margin-bottom', footerHeight);
  }).resize();


  // chromebug for autoplay
  var video = document.getElementById('video');
  // video.play();

  // hero in viewport
  var element = $('.hero');
  var elementTop = element.length ? element.position().top : 0;
  var elementHeight = element.length ? element.height() : 0;
  var viewportHeight = $(window).height();

  $(window).on('scroll', function() {
      if (!video) return;
      var scrollTop = $(window).scrollTop();
    
      if (scrollTop + viewportHeight > elementTop && scrollTop < elementTop + elementHeight) {
          video.play();
      } else {
          video.pause();
      }
  });
}


// Init Lightbox for elements with data-large-src attribute 
function setupLightbox() {
  var lb = Object.create(Lightbox);
  lb.init('[data-large-src]');
}

function isProject() {    
  return $('body').hasClass('project');
}


// Setup page on DOM ready
$(function () {
   
  // Attach FastClick.js to DOM body
  fastclick(document.body);

  // Initialize Flickity
  setupFlickity('.lightbox', '.lightbox-cell');

  // Preload all images 
  setupImages();

  // Initialize Lightbox 
  setupLightbox();
  
  // Setup listeners 
  setupListeners();

});